import Optimize from '../../assets/images/optimize.svg'
import Quickly from '../../assets/images/quickly.svg'
import Multiscreen from '../../assets/images/multiscreen.svg'
import Monetize from '../../assets/images/monetize.svg'
import Template1 from '../../assets/images/template1.png'
import Template2 from '../../assets/images/template2.png'
import React, { useEffect } from 'react'
import * as basicScroll from 'basicscroll'

export default function AppTemplate () {
    useEffect(() => {
        const instance = basicScroll.create({
            elem: document.querySelector('.templates'),
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                '--template1': {
                    from: '-100px',
                    to: '150px',
                },
                '--template2': {
                    from: '150px',
                    to: '-100px',
                }
            },
        })

        instance.start()

        return () => {
            instance.destroy()
        }
    });

    return <div className="bg-gray-100 py-16 lg:py-32 relative overflow-hidden">
        <div className="container mx-auto px-6 flex">
            <div className="sm:w-1/2">
                <div className="flex flex-col mb-8">
                    <h2 className="font-varela text-3xl sm:text-4xl text-gray-800">App-like templates</h2>
                    <span className="bg-primary h-1 w-16 rounded-full mt-4"/>
                </div>
                <p className="text-lg text-gray-600 mb-10">HS Mobile delivers such a lighting fast and immersive
                    user experience, you won't differentiate if you are browsing a web site or using an native
                    app.</p>
                <ul className="flex flex-wrap">
                    <li className="lg:w-1/2 flex mb-10 lg:pr-12">
                        <img alt="Optimize" src={Optimize} className="h-12 mr-4"/>
                        <div>
                            <h4 className="text-2xl font-varela text-gray-800">Optimize</h4>
                            <p className="text-gray-600">Every single technical aspect is optimized to the
                                most (CDNs, caching,...)</p>
                        </div>
                    </li>
                    <li className="lg:w-1/2 flex mb-10 lg:pl-12">
                        <img alt="Quickly" src={Quickly} className="h-12 mr-4"/>
                        <div>
                            <h4 className="text-2xl font-varela text-gray-800">Speed</h4>
                            <p className="text-gray-600">Every mms, every byte, matters.</p>
                        </div>
                    </li>
                    <li className="lg:w-1/2 flex mb-10 lg:pr-12">
                        <img alt="Multiscreen" src={Multiscreen} className="h-12 mr-4"/>
                        <div>
                            <h4 className="text-2xl font-varela text-gray-800">Multi-screen</h4>
                            <p className="text-gray-600">Hs Mobile works in all devices out of the box.</p>
                        </div>
                    </li>
                    <li className="lg:w-1/2 flex mb-10 lg:pl-12">
                        <img alt="Monetize" src={Monetize} className="h-12 mr-4"/>
                        <div>
                            <h4 className="text-2xl font-varela text-gray-800">Monetize</h4>
                            <p className="text-gray-600">Hs Mobile uses its own algorithm to <a className="text-primary font-bold" href="#monetization">get the maximum
                                revenue</a>.</p>
                        </div>
                    </li>
                </ul>
                <div className="flex flex-col mb-0">
                    <h3 className="font-varela text-3xl sm:text-4xl text-gray-800">Real Metrics</h3>
                    <span className="bg-primary h-1 w-16 rounded-full mt-4"/>
                    <div className="flex flex-col sm:flex-row mt-8 text-gray-800">
                        <div className="sm:w-1/3 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                            <b className="text-3xl text-gray-600">+50%</b> Page Views
                        </div>
                        <div className="sm:w-1/3 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                            <b className="text-3xl text-gray-600">-70%</b> Bounce Rate
                        </div>
                        <div className="sm:w-1/3 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                            <b className="text-3xl text-gray-600">+75%</b> Time on site
                        </div>
                    </div>
                    <div className="w-full text-center mt-6 text-gray-600">*Real avg metrics from customer's Google Analytics</div>
                </div>
            </div>

        </div>

        <div className="hidden sm:block w-1/2 h-full absolute top-0 left-1/2 flex pl-16 templates">
            <img alt="Template1" src={Template1} className="template1 absolute bottom-1/2 w-80"/>
            <img alt="Template2" src={Template2} className="template2 absolute top-1/2 left-0 w-80 ml-64"/>
        </div>
    </div>
}
