import Thunderbolt1 from '../../assets/images/thunderbolt1.svg'
import Thunderbolt2 from '../../assets/images/thunderbolt2.svg'
import Safari from '../../assets/images/safari.png'
import Recetas from '../../assets/images/recetaslight.png'
import Arrow from '../../assets/images/arrow.svg'
import Recetas2 from '../../assets/images/recetaslight2.png'
import React, { useEffect } from 'react'
import * as basicScroll from 'basicscroll'

export default function Claim () {

    useEffect(() => {
        const instance = basicScroll.create({
            elem: document.querySelector('.screenshot'),
            from: '0',
            to: '920px',
            props: {
                '--screenshot': {
                    from: '0px',
                    to: '-200px',
                },
            },
        })

        instance.start()

        return () => {
            instance.destroy()
        }
    });

    return <div className="claim">
        <div className="claim__background w-full h-full overflow-hidden absolute top-0 left-0">
            <img alt="Thunderbolt1" src={Thunderbolt1} className="claim__thunderbolt1 absolute opacity-75"/>
            <img alt="Thunderbolt2" src={Thunderbolt2} className="claim__thunderbolt2 absolute opacity-75"/>
        </div>
        <div className="container mx-auto px-6 px-8 xl:px-32 relative pt-40 sm:pt-56 flex items-start justify-between">
            <div className="px-2 sm:px-0 flex flex-col items-center">
                <span className="bg-black-opacity-1 py-2 px-2 sm:px-6 rounded-full text-white mb-4 text-sm sm:text-base flex w-full sm:w-auto justify-center">Responsive version</span>
                <div className="mobile flex justify-center bg-white">
                    <div className="screen relative overflow-hidden w-full h-full">
                        <img alt="Safari" src={Safari} className="w-full absolute top-0 left-0 z-10"/>
                        <img alt="Divina" src={Recetas} className="screenshot w-full object-cover"/>
                    </div>
                </div>
                <h3 className="font-varela text-3xl text-gray-700 mt-4">4.1s</h3>
            </div>
            <div className="hidden lg:flex flex-col items-center justify-center text-center flex-1 pt-32 px-6 xl:px-0">
                <ul className="font-varelia text-3xl text-white">
                    <li className="my-6 flex items-center">
                        <span className="h-1 w-12 bg-white rounded-full opacity-25"/>
                        <span className="w-64">faster</span>
                        <img alt="Arrow" src={Arrow} className=" opacity-25"/>
                    </li>
                    <li className="my-6 flex items-center">
                        <span className="h-1 w-12 bg-white rounded-full opacity-25"/>
                        <span className="w-64">more revenue</span>
                        <img alt="Arrow" src={Arrow} className=" opacity-25"/>
                    </li>
                    <li className="my-6 flex items-center">
                        <span className="h-1 w-12 bg-white rounded-full opacity-25"/>
                        <span className="w-64">improved ux</span>
                        <img alt="Arrow" src={Arrow} className=" opacity-25"/>
                    </li>
                    <li className="my-6 flex items-center">
                        <span className="h-1 w-12 bg-white rounded-full opacity-25"/>
                        <span className="w-64">no develop</span>
                        <img alt="Arrow" src={Arrow} className=" opacity-25"/>
                    </li>
                    <li className="my-6 flex items-center">
                        <span className="h-1 w-12 bg-white rounded-full opacity-25"/>
                        <span className="w-64">less resources</span>
                        <img alt="Arrow" src={Arrow} className=" opacity-25"/>
                    </li>
                </ul>
            </div>
            <div className="px-2 sm:px-0 flex flex-col items-center">
                <span className="bg-black-opacity-1 py-2 px-2 sm:px-6 rounded-full text-white mb-4 text-sm sm:text-base flex w-full sm:w-auto justify-center">HS Mobile</span>
                <div className="mobile flex justify-center bg-white">
                    <div className="screen relative overflow-hidden w-full h-full">
                        <img alt="Safari" src={Safari} className="w-full absolute top-0 left-0 z-10"/>
                        <img alt="Recetas2" src={Recetas2} className="screenshot w-full object-cover"/>
                    </div>
                </div>
                <h3 className="font-varela text-3xl text-gray-700 mt-4">0.3s</h3>
            </div>
        </div>
    </div>
}
