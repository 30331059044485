import Mockup from '../../assets/images/mockup.png'
import Semseo from '../../assets/images/semseo.svg'
import Ssmas from '../../assets/images/ssmas.svg'
import Twitter from '../../assets/images/twitter.svg'
import Facebook from '../../assets/images/facebook.svg'
import Linkedin from '../../assets/images/linkedin.svg'
import Certified from '../../assets/images/certified-hor.png'
import React, { useState } from 'react';

import {email, onChange, Diverror, Loader} from './Email'

export default function ContactUs () {
    const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        company: '',
        url: '',
        email: '',
        phone_number: '',
    });
    //Variable error, vista y cargador
    const [error ,setError ] = useState("");
    const [loader ,setLoader ] = useState(false);
    return <div className="pt-16 lg:pt-32 pb-16 relative" id="contact">
        <img alt="Mockup" src={Mockup} className="hidden lg:block absolute bottom-0 left-0 -ml-88 xl:-ml-32 -mb-20"/>
        <div className="container mx-auto px-6 flex justify-end relative z-10">
            <div className="w-full lg:w-2/3 flex flex-col">
                <div className="flex flex-col mb-12">
                    <h2 className="font-varela text-4xl text-gray-800">Contact us</h2>
                    <span className="bg-primary h-1 w-16 rounded-full mt-4"></span>
                </div>
                <form name="contact" onSubmit={(e) => email(e, formData, setFormData, setError, setLoader)} className="flex flex-col">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="flex mb-8">
                        <div className="w-full pr-4">
                            <input type="text" name="name" placeholder="Name" required onChange={(e) => onChange(e, formData, setFormData)}
                                   className="text-lg text-gray-800 py-2 px-4 h-12 border boder-gray-300 rounded w-full bg-gray-100 focus:outline-none focus:border-gray-500"/>
                        </div>
                        <div className="w-full pl-4">
                            <input type="text" name="last_name" placeholder="Last name" required onChange={(e) => onChange(e, formData, setFormData)}
                                   className="text-lg text-gray-800 py-2 px-4 h-12 border boder-gray-300 rounded w-full bg-gray-100 focus:outline-none focus:border-gray-500"/>
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <div className="w-full pr-4">
                            <input type="text" name="company" placeholder="Company" onChange={(e) => onChange(e, formData, setFormData)}
                                   className="text-lg text-gray-800 py-2 px-4 h-12 border boder-gray-300 rounded w-full bg-gray-100 focus:outline-none focus:border-gray-500"/>
                        </div>
                        <div className="w-full pl-4">
                            <input type="text" name="url" placeholder="Site URL" required onChange={(e) => onChange(e, formData, setFormData)}
                                   className="text-lg text-gray-800 py-2 px-4 h-12 border boder-gray-300 rounded w-full bg-gray-100 focus:outline-none focus:border-gray-500"/>
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <div className="w-full pr-4">
                            <input type="email" name="email" placeholder="Email" required onChange={(e) => onChange(e, formData, setFormData)}
                                   className="text-lg text-gray-800 py-2 px-4 h-12 border boder-gray-300 rounded w-full bg-gray-100 focus:outline-none focus:border-gray-500"/>
                        </div>
                        <div className="w-full pl-4">
                            <input type="text" name="phone_number" placeholder="Phone number" onChange={(e) => onChange(e, formData, setFormData)}
                                   className="text-lg text-gray-800 py-2 px-4 h-12 border boder-gray-300 rounded w-full bg-gray-100 focus:outline-none focus:border-gray-500"/>
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <div className="w-full pr-4">
                    I read and accept the <a rel="nofollow" target="_blank" href="https://ssmas.com/privacy-policy">policy privacy</a> <input type="checkbox" name="accept" value="1" required />
                        </div>
                        <div className="w-full pl-4">
                        {loader ?(
                            <Loader></Loader>
                        ):(
                            <button
                                type="submit"
                                className="bg-primary py-3 px-12 rounded ml-auto uppercase text-white font-bold">Send
                            </button>
                        )}
                            
                        </div>
                    </div>
                    <Diverror error={error}/>
                </form>
                <div className="w-full lg:w-2/3 border-t border-gray-200 pt-16 mt-16 lg:mt-32 ml-auto flex flex-col items-end">
                    <div className="flex mb-8">
                        <div className="flex flex-col items-end mr-8">
                            <img alt="Semseo" src={Ssmas} className="mb-4"/>
                            <div className="flex">
                                <a href="https://twitter.com/semseoymas" className="mr-3">
                                    <img alt="Twitter" src={Twitter}/>
                                </a>
                                <a href="https://www.facebook.com/semseoymas" className="mr-3">
                                    <img alt="Facebook" src={Facebook}/>
                                </a>
                                <a href="https://www.linkedin.com/company/sem-seo-y-m-s">
                                    <img alt="LinkedIn" src={Linkedin}/>
                                </a>
                            </div>
                        </div>
                        <img alt="Certified" src={Certified} className="h-20"/>
                    </div>
                    <p className="mb-1 text-sm text-gray-600">2020 © SEM SEO Y MAS online marketing SL.</p>
                    <p className="mb-1 text-sm"><a target="_blank" rel="nofollow" href="https://ssmas.com/legal-notice" className="text-primary hover:underline">Legal
                        notice</a></p>
                </div>
            </div>
        </div>
    </div>
}
