import React from 'react'
import Claim from './Home/Claim'
import Default from '../Layout/Default'
import Features from './Home/Features'
import ContactUs from './Home/ContactUs'
import Monetization from './Home/Monetization'
import AppTemplate from './Home/AppTemplate'


export default function Home() {

    return (
        <Default className="App">
            <Claim/>
            <Features/>
            <AppTemplate/>
            <Monetization/>
            <ContactUs/>
        </Default>
    );
}

