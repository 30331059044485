import Confetti from '../../assets/images/confetti.svg'
import Chart from '../../assets/images/chart.svg'
import Dfp from '../../assets/images/dfp.svg'
import Search from '../../assets/images/search.svg'
import Ads from '../../assets/images/ads.svg'
import Certified from '../../assets/images/certified-hor.png'
import React from 'react'

export default function Monetization () {
    return <div id="monetization" className="py-16 sm:py-32 relative monetizacion">
        <img alt="Confetti" src={Confetti} className="absolute bottom-0 w-full object-cover"/>
        <div className="container mx-auto px-6 relative z-10">
            <div className="flex flex-col items-center mb-20">
                <h2 className="font-varela text-3xl sm:text-4xl text-gray-800 text-center">Optimizing monetization results</h2>
                <span className="bg-primary h-1 w-16 rounded-full mt-4"></span>
            </div>
            <div className="flex flex-col sm:flex-row pb-12 sm:pb-24 border-b border-gray-300 sm:mb-24">
                <div className="sm:w-1/4 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                    <img alt="Chart" src={Chart} className="h-16 mb-8"/>
                    <h4 className="font-varela text-gray-800 text-2xl mb-3">Optimize revenue</h4>
                    <p className="text-gray-600">Showing advertising just where is effective, optimizing
                        CPMs with auto pricing rules, <b>header bidding</b> and programmatic deals.</p>
                </div>
                <div className="sm:w-1/4 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                    <img alt="Dfp" src={Dfp} className="h-16 mb-8"/>
                    <h4 className="font-varela text-gray-800 text-2xl mb-3">AdManager</h4>
                    <p className="text-gray-600">As <a
                        href="https://www.google.com/ads/publisher/partners/find-a-partner/#!?modal_active=modal-sem-seo&countries=ES#%3Fmodal_active=none"
                        target="_blank" rel="noopener noreferrer" className="text-primary hover:underline"><span className="text-primary font-bold">Google Certified Publishing Partners</span></a> we
                        have the experience and knowledge to advise you in the achievement of your
                        objectives using AdSense, GAM and AdExchange.</p>
                </div>
                <div className="sm:w-1/4 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                    <img alt="Search" src={Search} className="h-16 mb-8"/>
                    <h4 className="font-varela text-gray-800 text-2xl mb-3">Increase viewability</h4>
                    <p className="text-gray-600">Viewability rules. Our proprietary technology
                        automatically detects the best placements and positions to obtain the highest viewability.
                        Get the KPI that the best advertisers demand.</p>
                </div>
                <div className="sm:w-1/4 text-center flex flex-col items-center mb-12 sm:mb-0 sm:px-4">
                    <img alt="Ads" src={Ads} className="h-16 mb-8"/>
                    <h4 className="font-varela text-gray-800 text-2xl mb-3">Auto ads</h4>
                    <p className="text-gray-600">Always in line with Better Ads Coalition
                        guidelines. Get the perfect ad density to comply with all policies in native, video (In/OutStream), display or matched content ads.</p>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center">
                <img alt="Certified" src={Certified}/>
                <div className="sm:w-1/2 sm:ml-12 mt-8 sm:mt-0 text-center sm:text-left">
                    <h4 className="text-2xl font-varela text-gray-800 mb-4">Google Certified Publishing
                        Partner</h4>
                    <p className="text-xl text-gray-600">This badge indicates that we meet the rigorous
                        qualification standards set by Google. More than that we are trusted business
                        partner with Google. Need AdExchange, AdManager or Adsense consultancy? <a
                            href="https://www.google.com/ads/publisher/partners/find-a-partner/#!?modal_active=modal-sem-seo&countries=ES#%3Fmodal_active=none"
                            target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Ask
                            Google</a> who is the best to help you.</p>
                </div>
            </div>
        </div>
    </div>
}
