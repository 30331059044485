import Certified from '../assets/images/certified.png'
import MainLogo from '../assets/images/logo.svg'
import React from 'react'

export default function Header () {
    return <header className="p-4 flex flex-wrap items-start justify-center sm:justify-between absolute left-0 top-0 w-full z-10">
        <div className="sm:w-1/3">
            <img src={Certified} className="h-14 sm:h-24 absolute sm:relative left-0 ml-3 sm:ml-0" alt="Certified"/>
        </div>
        <img src={MainLogo} className="h-12 sm:h-16 py-1" alt="Main logo"/>
        <div className="w-1/3 hidden sm:flex justify-end">
            <a
                href="#contact"
                className="px-8 py-3 uppercase font-bold text-white text-lg bg-white-opacity-1 rounded hover:bg-white hover:text-primary">Contact
                us
            </a>
        </div>
    </header>
}

