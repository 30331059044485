import Thunderbolt3 from '../../assets/images/thunderbolt3.svg'
import Thunderbolt4 from '../../assets/images/thunderbolt4.svg'
import Amp from '../../assets/images/amp.svg'
import Logo from '../../assets/images/logo-color.svg'
import App from '../../assets/images/app.svg'
import Instant from '../../assets/images/instant-articles.svg'
import React from 'react'

export default function Features () {
    return <div className="pt-40 relative overflow-hidden">
        <img alt="Thunderbolt3" src={Thunderbolt3} className="hidden lg:block absolute bottom-0 left-0 mb-64"/>
        <img alt="Thunderbolt4" src={Thunderbolt4} className="hidden lg:block absolute top-0 right-0 opacity-75"/>
        <div className="container mx-auto px-6">
            <div className="flex flex-col items-center">
                <h2 className="font-varela text-3xl lg:text-4xl text-gray-800">360º Mobile Solution</h2>
                <span className="bg-primary h-1 w-16 rounded-full mt-4"/>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between mt-12 lg:mt-24 mb-32 relative">
                <div className="hidden lg:block circle border-4 border-gray-300 absolute rounded-full"/>
                <div className="lg:w-1/3 px-6 lg:px-14 relative z-10 mb-10 lg:mb-0">
                    <div
                        className="bg-white shadow-custom rounded py-12 px-6 flex flex-col justify-center text-center w-full h-88">
                        <img alt="Amp" src={Amp} className="h-16 mb-6"/>
                        <h4 className="font-varela text-2xl text-gray-700 leading-tight mb-3">Accelerated Mobile Pages Project</h4>
                        <p className="text-gray-600">Improve web content performance with AMP technology. <b>Sites that Google loves</b>.</p>
                    </div>
                </div>
                <div className="flex flex-col lg:w-1/3 px-6 lg:px-14 relative z-10">
                    <div
                        className="bg-white shadow-custom rounded py-12 px-6 flex flex-col justify-center text-center w-full mb-10 lg:mb-12 h-88">
                        <img alt="Logo" src={Logo} className="h-12 mb-6"/>
                        <h4 className="font-varela text-2xl text-gray-700 leading-tight mb-3">HighSpeed Mobile</h4>
                        <p className="text-gray-600">A simple new way for publishers to easily
                            transform their sites into a beautiful, optimized, lighting fast
                            experience improving interaction metrics and revenue.</p>
                    </div>
                    <div
                        className="bg-white shadow-custom rounded py-12 px-6 flex flex-col justify-center text-center w-full mb-10 lg:mb-0 lg:mt-12 h-88">
                        <img alt="App" src={App} className="h-16 mb-6"/>
                        <h4 className="font-varela text-2xl text-gray-700 leading-tight mb-3">Native
                            App</h4>
                        <p className="text-gray-600">We develop your own native app for the 2 main mobile operating systems.</p>
                    </div>
                </div>
                <div className="lg:w-1/3 px-6 lg:px-14 relative z-10">
                    <div
                        className="bg-white shadow-custom rounded py-12 px-6 flex flex-col justify-center text-center w-full h-88">
                        <img alt="Instant" src={Instant} className="h-16 mb-6"/>
                        <h4 className="font-varela text-2xl text-gray-700 leading-tight mb-3">Instant
                            Articles</h4>
                        <p className="text-gray-600">Enable your content in Facebook's platform to
                            be more engaging and shareable.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
