import React from 'react';
import Home from './Views/Home'

function App() {
  return (
      <Home />
  );
}

export default App;
