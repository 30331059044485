import React from 'react'
import Header from './Header'

export default function Default({ children }) {
    return (
        <div className="App">
            <Header />
            {children}
        </div>
    );
}
