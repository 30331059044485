
import axios from 'axios';
import React from 'react';
export const email = async (e, formData, setFormData, setError, setLoader) => {
    setLoader(true);
    setFormData({ ...formData, [e.target.name]: e.target.value });

    let body = JSON.stringify(formData);
    e.preventDefault();
    let url = process.env.NODE_ENV == "development" ? "http://localhost/hs-mobile" : ""

    await axios
        .post(url + "/backend/APIS/email.php", body)
        .then(function (response) {
            if(response.data.exito){
                setError("Email sent succesfully");
                setLoader(false);
            }else{
                setLoader(false);
                setError(response.data.error);
                console.log(response.data.error)
            }
        });
        setLoader(false);

};
//-------------------Update Forms----------------------

export const onChange = (e, formData, setFormData) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

//---------------------Error---------------------------
export const Diverror = ({error}) => {
    return (
        <div>{error}</div>
    );
};

export const Loader = () => {
    return (
        <div className="lds-dual-ring"></div>
    );
};